@use "sass:math";

//
//  Animation variables
//  _____________________________________________

$animation-bounce__translate                      : 30% !default;
$animation-bounce__duration-l                     : 1s !default;
$animation-bounce__duration                       : .75s !default;
$animation-bounce__iteration-count                : 3 !default;
$animation-bounce__iteration-count-s              : 1 !default;
$animation-bounce__timing-function                : ease-in-out !default;

@keyframes bounce-left {
    0%   { transform: translateX(0); }
    50%  { transform: translateX(-$animation-bounce__translate); }
    100% { transform: translateX(0); }
}

@keyframes bounce-right {
    0%   { transform: translateX(0); }
    50%  { transform: translateX($animation-bounce__translate); }
    100% { transform: translateX(0); }
}

@keyframes bounce-top {
    0%   { transform: translateY(0); }
    25%  { transform: translateY(math.div($animation-bounce__translate, 2)); }
    75%  { transform: translateY(-$animation-bounce__translate); }
    100% { transform: translateY(0); }
}

@keyframes rotateMinutes {
    0%   { transform:rotateZ(0deg); }
    100% { transform:rotateZ(360deg); }
}